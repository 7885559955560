<template>
  <div>
  <ns-data-table
    :headers="supplyHeader"
    class="my-table supply"
    dense
    :request-service="getRequestService"
    :search-params="params"
    :tableOffsetBottom="20"

  >

    <!-- //header -->
    <!-- eslint-disable-next-line -->
    <template v-slot:[`header.btn`]>
      <VIcon dense> mdi-cogs</VIcon>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:[`header.reference_number`]>
      <div class="header mt-4">
        <VTextField
          v-on:keypress="validationForNumbers($event)"
          @paste="validationForNumbers($event)"
          dense
          hide-details
          solo
          :placeholder="'№'"
          v-model="filterProps.number"
          class="text-caption"
          clearable
          @input="onScanerField"
        />
      </div>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:[`header.ttn`]>
      <div class="header mt-4">
        <VTextField
          v-on:keypress="validationForNumbers($event)"
          @paste="validationForNumbers($event)"
          dense
          hide-details
          solo
          v-model="filterProps.ttn"
          clearable
          :placeholder="$t('table.ttn')"
          @input="onScanerField"
          class="text-caption"
        />
      </div>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:[`header.ns_code`] v-if="!permissions.client">
      <div class="header mt-4 ">
        <VTextField
          v-on:keypress="validationForNumbers($event)"
          @paste="validationForNumbers($event)"
          dense
          @input="onScanerField"
          hide-details
          solo
          :placeholder="$t('table.ns_code')"
          v-model="filterProps.cell_details__ns_code"
          clearable
          class="text-caption"
        />
      </div>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:[`header.first_name`]>
      <div class="header mt-4">
        <VTextField
          v-on:keypress="validationForName($event)"
          @paste="validationForName($event)"
          dense
          hide-details
          solo
          :placeholder="$t('table.name')"
          v-model="filterProps.name"
          clearable
          class="text-caption"
          @input="onScanerField"
        />
      </div>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:[`header.last_name`]>
      <div class="header mt-4">
        <VTextField
          v-on:keypress="validationForSurname($event)"
          @paste="validationForSurname($event)"
          dense
          hide-details
          solo
          :placeholder="$t('table.surname')"
          v-model="filterProps.surname"
          clearable
          class="text-caption"
          @input="onScanerField"
        />
      </div>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:[`header.phone_number`]>
      <div class="header mt-4">
        <VTextField
          dense
          hide-details
          solo
          :placeholder="$t('table.phone')"
          v-model="filterProps.phone"
          v-on:keypress="validationForPhoneNumber($event)"
          @paste="validationForPhoneNumber($event)"
          class="text-caption"
          clearable
          @input="onScanerField"
        />
      </div>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:[`header.city`] v-if="permissions.client">
      <div class="header mt-4">
        <VTextField
          v-on:keypress="validationForCities($event)"
          @paste="validationForCities($event)"
          dense
          hide-details
          solo
          :placeholder="$t('table.city')"
          v-model="filterProps.delivery_address_details__name_city"
          clearable
          @input="onScanerField"
        />
      </div>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:[`header.delivery_state`]>
      <div class="header mt-4">
        <VSelect
          dense
          :items="Object.values(states)"
          :item-text="getStatusText"
          :item-value="'id'"
          hide-details
          solo
          @change="onScanerField"
          :placeholder="$t('table.delivery_status')"
          v-model="filterProps.state"
          clearable
          class="text-caption"
          :menu-props="{ bottom: true, offsetY: true }"
        >
          <template v-slot:item="{ item }">
            <div class="d-flex align-center justify-content-start ">
              <StatusCircle :status-name="item.id" :type="'supply'" class="pb-1"/>
              <div class="elispsis">
                {{ getStatusText(item) }}
              </div>
            </div>
          </template>
        </VSelect>
      </div>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:[`header.time_created`]>
      <div class="header mt-4">
        <v-menu
          ref="menuFirstDate"
          v-model="dateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
          class="text-caption"
          left
          bottom
          offset-overflow

        >
          <template v-slot:activator="{ on, attrs }">
            <VTextField
              solo
              :placeholder="$t('form.time_created')"
              v-model="dateRangeText"
              readonly
              append-icon="mdi-calendar"
              dense
              hide-details
              v-bind="attrs"
              v-on="on"
              style="width: 220px"
            >
              <template v-slot:append-outer>
                <v-slide-x-reverse-transition>
                  <VIcon
                    style="position: relative; bottom: 4px;"
                    @click="onCancelInterval"
                    v-if="time_created_period.length >= 1"
                  >mdi-close
                  </VIcon
                  >
                </v-slide-x-reverse-transition>
              </template>
            </VTextField>
          </template>
          <v-date-picker
            v-model="time_created_period"
            range
            no-title
            scrollable
            :locale="currentLanguage.key"
            min="2021"
            :max="new Date().toISOString().substr(0, 10)"
            @input="onChangeDatePeriod"
          >
          </v-date-picker>
        </v-menu>
      </div>
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.reference_number="{ item }">
            <span class="copyTxt mr-1" @click="copyToClipboard(item.number)">{{
                item.number || "--"
              }}</span>
      <v-tooltip top v-if="item.id_origin">
        <template v-slot:activator="{ on, attrs }">
          <VIcon v-bind="attrs" v-on="on" small color="success">
            mdi-arrow-left
          </VIcon>
        </template>
        Origin ID: {{ item.id_origin }}
      </v-tooltip>
      <v-tooltip top v-if="item.is_blocked === '1'">
        <template v-slot:activator="{ on, attrs }">
          <VIcon :size="isLarge ? '20' : '14'" color="error" v-bind="attrs" v-on="on">
            mdi-lock
          </VIcon>
        </template>
        <div class="pa-1">
          {{ $t("supply.blocked") }}
        </div>
      </v-tooltip>
      <v-tooltip top v-if="item.is_claim === '1'">
        <template v-slot:activator="{ on, attrs }">
          <VIcon v-bind="attrs" v-on="on" small color="warning">
            mdi-alert-outline
          </VIcon>
        </template>
        <div class="pa-1">
          {{ $t("supply.sending_with_a_claim") }}
        </div>
      </v-tooltip>
      <v-tooltip top v-if="item.is_items_issue === '1'">
        <template v-slot:activator="{ on, attrs }">
          <VIcon v-bind="attrs" v-on="on" small color="error">
            mdi-clipboard-remove-outline
          </VIcon>
        </template>
        <div class="pa-1">
          {{ $t("supply.problem_with_returned_items") }}
        </div>
      </v-tooltip>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.btn="{ item }">
        <ns-badge
          :show-badge="item.cell_details.description !== '' && item.cell_details.description !== ''"
          :ns-border="true"
          ns-color="warning"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <VBtn
                class="btn-edit ml-1"
                :color="item.uuid_parent !== '00000000-0000-0000-0000-000000000000' ? '#D62032' : 'primary'"
                dark
                depressed
                small
                :class="{ sm: !isLarge, 'ml-4': isLarge, 'ml-1': !isLarge }"
                @click="onSupplyItemClick(item)"
                v-bind="attrs"
                v-on="on"
              >
                <VIcon v-if="item.uuid_parent !== '00000000-0000-0000-0000-000000000000'">
                  mdi-arrow-u-left-top-bold
                </VIcon>
                <VIcon v-else>
                  mdi-file-document-edit-outline
                </VIcon>
              </VBtn>
            </template>
            <span class="text-caption">
          {{ $t("supply.amount_of_products") }}: {{ item.metrics.count_dd_items }}
        </span>
          </v-tooltip>
        </ns-badge>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.ttn="{ item }">
      <div v-if="item.ttn == 0 || item.ttn == null">
        <div class="new__wrapper" :class="{ sm: !isLarge }">
          <span>
            {{ $t("table.new") }}
          </span>
        </div>
      </div>
      <div v-else @click="copyToClipboard(item.ttn)" class="copyTxt">
        {{ item.ttn || "--" }}
      </div>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-if="!permissions.client" v-slot:item.ns_code="{ item }">
      <div @click="copyToClipboard(item.cell_details.ns_code)" class="copyTxt">
        {{ item.cell_details.ns_code || "--" }}
      </div>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.first_name="{ item }">
      <div class="elispsis" v-if="!permissions.client">
        {{ item.user_details.name || "--" }}
      </div>
      <div class="elispsis" v-else>{{ item.delivery_address_details.name || "--" }}</div>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.last_name="{ item }">
      <div class="elispsis" v-if="!permissions.client">
        {{ item.user_details.surname || "--" }}
      </div>
      <div class="elispsis" v-else>{{ item.delivery_address_details.surname || "--" }}</div>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.phone_number="{ item }">
      <div v-if="!permissions.client">
        <div
          v-if="item.user_details.phone"
          @click="copyToClipboard(item.user_details.phone)"
          class="copyTxt"
        >
          {{ item.user_details.phone | VMask("+38 (###) ##-##-###") }}
        </div>
        <div v-else>--</div>
      </div>
      <div v-else>
        <div
          @click="copyToClipboard(item.delivery_address_details.phone)"
          class="copyTxt"
          v-if="
            item.delivery_address_details.phone &&
              item.delivery_address_details.phone !== '+38'
          "
        >
          {{ item.delivery_address_details.phone | VMask("+38 (###) ##-##-###") }}
        </div>
        <div v-else>--</div>
      </div>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-if="permissions.client" v-slot:item.city="{ item }" class="md">
      {{ item?.delivery_address_details?.name_city || "--" }}
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.delivery_state="{ item }" class="md">
      <div class="d-flex align-center justify-content-start ">
        <StatusCircle
          :status-name="item.id_state"
          :type="'supply'"
          :class="{ sm: !isLarge }"
        />
        <div class="elispsis">
          {{ item.state_details[`${currentLanguage.key}_name`] || item.state_details.name || '' }}
        </div>
      </div>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.time_created="{ item }" class="md">
      {{ item.time_created | createdAt(item.time_created, currentLanguage) }}
    </template>
  </ns-data-table>

    <EditSupplyInDialog
      v-if="editInDialog"
      :visible="editInDialog"
      @close="editInDialog = false"
      :supply="supplyItem"
      @add-customer="$emit('add-customer')"
    />
    <DialogWrapper
      v-if="editOutDialog"
      :visible="editOutDialog"
      @close="editOutDialog = false"
      :typeDialog="'edit'"
      settingDialog="supply"
    >
      <OrderDialog
        :uuidItem="supplyItem.uuid"
        :typeDialog="'edit'"
        settingDialog="supply"
        v-if="editOutDialog"
      ></OrderDialog>
    </DialogWrapper>
  </div>
</template>

<script>
import EditSupplyInDialog from "./EditSupplyInDialog.vue";
import OrderDialog from "../orderDialog/OrderDialog.vue";
import DialogWrapper from "../orderDialog/DialogWrapper.vue";
import user from "../../mixins/user";
import SizeUi from "../../mixins/SizeUi";
import StatusCircle from "../move-list/StatusCircle.vue";
import notifications from "../../mixins/notifications";
import NsDataTable from "@/components/UI/ns-data-table/ns-data-table.vue";
import supplyMixin from "@/components/supply/supplyMixin";
import EventBus from '../../events/EventBus';

export default {
  name: "SupplyTables",
  mixins: [user, SizeUi, notifications, supplyMixin],
  components: {
    NsDataTable,
    EditSupplyInDialog,
    OrderDialog,
    StatusCircle,
    DialogWrapper
  },
  props: {
    type: {
      required: true
    },
  },
  data: () => ({
    editInDialog: false,
    editOutDialog: false
  }),
  mounted() {
    EventBus.$on("supply-out-modified", (result, close) => {
      this.editInDialog = false;
      if(!close) {
        this.editOutDialog = false;
      }
    });
    this.checkStates();
  },
  methods: {
    onSupplyItemClick(item) {
      this.$emit("toggleRow", item.uuid);
      this.supplyItem = item;
      if (item.type === "in") {
        this.editInDialog = true;
      } else {
        this.editOutDialog = true;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.statusBox {
  background: rgba(59, 119, 174, 0.1);
  border-radius: 6px;
  font-size: 80%;
  color: rgba(72, 148, 217, 1);
  padding: 2px 5px;
  line-height: 15px;
}
.v-tooltip__content {
  padding: 2px !important;
}
</style>
