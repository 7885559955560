<template>
  <VDialog
    v-model="visibility"
    max-width="700px"
    :persistent="isChanged"
    @click:outside="showConfirmDialog"
    content-class="cross__dialog dialog-order"
    :transition="$dialogTransition"
  >
    <VCard>
      <div class="cross__inner">
        <VBtn fab small  elevation="0" @click="showConfirmDialog">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </div>
      <VDivider class="mb-3"/>
      <VCardText>
        <VContainer class="py-0">
          <div class="text-center" v-if="loading">
            <VProgressCircular
              :size="50"
              color="primary"
              indeterminate
            ></VProgressCircular>
          </div>
          <div v-else>
            <VRow class="align-center mt-10">
              <VCol class="pb-0" cols="12" v-if="permissions.can_create_delivery">
                <v-autocomplete
                  dense
                  outlined
                  :label="$t('form.storage')"
                  :items="depots"
                  :item-text="'name'"
                  :item-value="'uuid'"
                  class="br-10"
                  color="success"
                  item-color="success"
                  @change="updateListClients"
                  :error-messages="depotUuidErrors"
                  :no-data-text="$t('table.noData')"
                  small-chips
                  deletable-chips
                  @keypress="validationForWarehouses($event)"
                  @paste="validationForWarehouses($event)"
                />
              </VCol>
              <VCol
                v-if="!permissions.client"
                cols="12" md="6" :class="{'my-0 py-0': permissions.can_create_delivery}"
                class="pb-3">
                <v-autocomplete
                  :disabled="!supplyItem.depot_uuid && permissions.can_create_delivery"
                  dense
                  outlined
                  :label="$t('form.client')"
                  :items="clients"
                  :item-text="'name'"
                  :item-value="'uuid'"
                  class="br-10"
                  color="success"
                  item-color="success"
                  v-model="supplyItem.client_uuid"
                  :error-messages="clientUuidErrors"
                  :no-data-text="language.NO_DATA_IS_AVAILABLE"
                  hide-details="auto"
                   small-chips
                  @change="checkChange"
                  @keypress="validationForName($event)"
                  @paste="validationForName($event)"
                >
                  <template slot="item" slot-scope="data">

                    {{ `${data.item.name} ${data.item.surname}` }}

                  </template>
                  <template slot="selection" slot-scope="data">
                  <VChip close @click:close="supplyItem.client_uuid = '' " small>
                    {{ `${data.item.name} ${data.item.surname}` }}
                    </VChip>
                  </template>
                </v-autocomplete>
              </VCol>
              <VCol
                v-if="!permissions.client"
                cols="12" md="6" class="mb-0 py-0 ml-0 pl-0 pb-3"
                :class="{'text-center mb-3 ' :$vuetify.breakpoint.smAndDown}">
                <span
                  class="mx-6 font-weight-bold"
                  style="color: black;"
                >{{$t('supply.or')}}</span>
                <span @click="$emit('add-customer')">
                  <VBtn text color="green" :class="{ 'text-caption': $vuetify.breakpoint.xs }">
                      <VIcon left>mdi-account-plus</VIcon>
                   {{$t('supply.add_new')}}
                  </VBtn>
                </span>
              </VCol>
              <VCol cols="12"
                    :class="{'my-0 py-0': !permissions.client}"
              >
                <VTextField
                  color="green"
                  :label="$t('form.ttn')"
                  class="br-10"
                  dense
                  outlined
                  v-model="supplyItem.ttn"
                  maxLength="19"
                  @input="checkChange"
                  @keypress="validationForNumbers($event)"
                  @paste="validationForNumbers($event)"
                />
              </VCol>
              <VCol cols="12" class="my-0 py-0">
                <VTextarea
                  color="green"
                  no-resize
                  rows="2"
                  class="br-10"
                  outlined dense
                  :label="$t('form.comment')"
                  counter
                  maxlength="200"
                  v-model="supplyItem.comment"
                  required
                  @input="checkChange"
                ></VTextarea>
              </VCol>
            </VRow>
          </div>
        </VContainer>
      </VCardText>
      <VCardActions>
        <VContainer>
          <VRow class="my-0 py-0">
            <VCol cols="6" class="my-0 py-0">
              <VBtn

                dark
                @click="visibility = false"
                block
                class="br-10 text-transform-none"
                :disabled="loading"
                :class="{ 'text-caption': $vuetify.breakpoint.xs }"
              >
               {{$t('btn.cancel')}}
              </VBtn>
            </VCol>
            <VCol cols="6" class="my-0 py-0">
              <VBtn
                class="success-bg text-white text-transform-none br-10 w-100"
                block
                :loading="loading"
                @click="onCreate"
                :disabled="!isChanged"
                :class="{ 'text-caption': $vuetify.breakpoint.xs }"
              >
               {{$t('btn.create')}}
              </VBtn>
            </VCol>
          </VRow>
        </VContainer>
      </VCardActions>
    </VCard>
    <ConfirmLeftDialog
      :visible="visibleConfirm"
      @close="visibleConfirm = false"
      @delete="onClose"
    />
  </VDialog>
</template>

<script>
import _ from "lodash";
import moment from 'moment';
import {validationMixin} from 'vuelidate';
import {required ,requiredIf} from 'vuelidate/lib/validators';
import ConfirmLeftDialog from '@/components/dialog/ConfirmLeftDialog.vue';
import language from '../../mixins/language';
import notifications from '../../mixins/notifications';
import supplyService from '../../services/request/supply/supplyService';
import EventBus from '../../events/EventBus';
import usersService from '../../services/request/users/usersService';
import loader from '../../mixins/loader';
import user from '../../mixins/user';
import depotService from '../../services/request/depot/depotService';
import {validationForName, validationForNumbers, validationForWarehouses} from "@/mixins/helpers";

export default {
  mixins: [language, notifications, validationMixin, loader, user],
  name: 'AddSupplyInDialog',
  validations: {
    supplyItem: {
      client_uuid: {
                required: requiredIf(function () {
          return !this.permissions.client;
        }),
      }
      // depot_uuid: { required },
    },
  },
  components: {
    ConfirmLeftDialog
  },
  beforeDestroy(){
    EventBus.$off('customer-added');
  },

  async mounted() {
    if (!this.permissions.can_create_delivery) {
      // this.supplyItem.depot_uuid = '1';
    }
    EventBus.$on('customer-added', async () => {
      try {
        this.customers = await usersService.getClientsList();
        this.depots = await depotService.getDepotsList();
        this.states = await depotService.getAllStates({
          type: 'DELIVERY_IN',
        });
        this.setLoading(false);
      } catch (e) {
        this.setLoading(false);
        console.log(e)
      }
    });
    try {
      if (this.permissions.can_create_user_deport) {
        this.depots = await depotService.getDepotsList();
      }
      if (!this.permissions.client) {
        const data = await usersService.getClientsList();
        this.clients = [
          ...data,
        ];
        // orderDialog('this.clients', this.clients);
        // orderDialog('this.depots', this.depots);
      }
      this.states = await depotService.getAllStates({
        type: 'DELIVERY_IN',
      });
      this.inputSupplyItem = {
        ...this.supplyItem
      };
    } catch (e) {
      console.log(e)
    }
  },
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
  },
  data: () => ({
    loading: false,
    clients: [],
    depots: [],
    states: [],
    visibleConfirm: false,
    inputSupplyItem: {},
    supplyItem: {
      ttn: '',
      comment: '',
      client_uuid: '',
      depot_uuid: '',
    },
    isChanged: false,
  }),

  methods: {
    validationForNumbers,
    validationForName,
    validationForWarehouses,
    checkChange(val) {

      this.isChanged = !_.isEqual(this.supplyItem, this.inputSupplyItem);

      // // orderDialog(!_.isEqual(this.supplyItem, this.inputSupplyItem));
      // orderDialog(this.supplyItem);
      // orderDialog(this.inputSupplyItem);

    },
    showConfirmDialog() {
      if (this.isChanged) {
        this.visibleConfirm = true
      } else {
        this.onClose();
      }
    },
    onClose() {
      this.clients = [];
      this.depots = [];
      this.$v.$reset();
      this.visibility = false;
    },
    async updateListClients(val) {
      if (val) {
        this.isChanged = true
      } else {
        this.isChanged = false

      }
      // orderDialog('val', val);
      try {
        const dataDelivery = await depotService.getDepotsList({
          uuid: val,
        });
        this.supplyItem.depot_uuid = dataDelivery[0].uuid_address;
        if (!this.permissions.client) {
          const data = await usersService.getClientsList({
            uuid_deport: val,
          });
          if (data) {
            this.clients = [
              ...data,
            ];
          } else {
            this.clients = [];
          }
          // // orderDialog('this.clients', this.clients);
          // // orderDialog('this.depots', this.depots);
        }
      } catch (e) {
        console.log(e)
      }
    },
    clearItem() {
      Object.keys(this.supplyItem).forEach((key) => {
        this.supplyItem[key] = '';
      });
    },
    async onCreate() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        try {
          // orderDialog(1);
          this.loading = true;
          const formData = new FormData();
          if (this.permissions.can_create_delivery) {
            if (!this.permissions.client) {
              formData.append('uuid_sender_address', this.supplyItem.client_uuid.uuid_address);
              formData.append('uuid_delivery_address', this.supplyItem.depot_uuid);
            }
          }
          if (!this.permissions.client) {
            formData.append('uuid_user', this.supplyItem.client_uuid);
          } else {
            formData.append('uuid_user', this.getUserProperty('uuid'));
          }
          formData.append('ttn', this.supplyItem.ttn);
          if (this.supplyItem.comment) {
            formData.append('description', this.supplyItem.comment);
          }
          // formData.append('name', "");
          formData.append('type', 'in');
          if (this.permissions.can_create_delivery) {
            // formData.append('uuid_deport', this.supplyItem.depot_uuid);
            // formData.append('id_state', this.supplyItem.id_state);
          }
          await supplyService.addSupplyItem(formData);
          this.loading = false;
          this.setSuccessNotification(this.$t('supply.delivery_successfully_added'));
          EventBus.$emit('supply-in-modified');
          this.visibility = false;
          this.clearItem();
          this.$v.$reset();
        } catch (e) {
          this.loading = false;
          console.log(e)
        }
      }
    },
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit('close');
      },
    },
    clientUuidErrors() {
      const errors = [];
        if (!this.permissions.client) {
          if (!this.$v.supplyItem.client_uuid.$dirty) {
            return errors;
          }
          // eslint-disable-next-line no-unused-expressions
          !this.$v.supplyItem.client_uuid.required
          && errors.push(this.$t('form.errors.NameRequired'));
        }

      return errors;
    },
    depotUuidErrors() {
      const errors = [];
      // if (this.permissions.can_add_income_by_deport) {
      //   if (!this.permissions.client) {
      //     if (!this.$v.supplyItem.depot_uuid.$dirty) {
      //       return errors;
      //     }
      //     // eslint-disable-next-line no-unused-expressions
      //     !this.$v.supplyItem.depot_uuid.required
      //     && errors.push(this.language.isRequired('Клієнт'));
      //   }
      // }
      return errors;
    },
  }
};
</script>

<style scoped>

</style>
